import axios from "axios";
import {getRegion} from "../components/regionSelector/RegionSelector";
import {getDateFromTimeFilter, getDateToTimeFilter} from "../components/page/Page";

class Rest {
    baseUrl =
        // "http://localhost:8081";
        "https://admin.referal.mgr.com.ru";
    // "http://192.168.50.91:8924";

    login = () => this.request("GET", "/login");

    partnerList = (params) => this.request("POST", "/admin/partner/list", {
        page: params.page,
        size: params.size,
        orders: params.orders
    });
    partnerCSV = (params) => this.request("POST", "/admin/partner/csv", {
        orders: params.orders
    });
    partnerPut = (params) => this.request("PUT", "/admin/partner", {
        address: params.address,
        contactName: params.contactName,
        contactPhone: params.contactPhone,
        contectEmail: params.contectEmail
    });
    partnerUpdate = (params) => this.request("POST", "/admin/partner/" + params.id, {
        address: params.address,
        contactName: params.contactName,
        contactPhone: params.contactPhone,
        contectEmail: params.contectEmail
    });
    partnerDelete = (id) => this.request("DELETE", "/admin/partner/" + id);

    campaignList = (params) => this.request("POST", "/admin/campaign/list", {
        page: params.page,
        size: params.size,
        orders: params.orders,
        type: params.typeFilter,
        source: params.utmSourceFilter,
        region: getRegion()
    });
    campaignCSV = (params) => this.request("POST", "/admin/campaign/csv", {
        orders: params.orders,
        type: params.typeFilter,
        source: params.utmSourceFilter,
        region: getRegion()
    });
    campaignPut = (params) => this.request("PUT", "/admin/campaign", {
        utmsource: params.utmsource,
        type: params.type,
        urlpattern: params.urlpattern,
        partner: params.partner,
        active: params.active,
        additional: params.additional,
        region: params.region,
        created: params.created
    });
    campaignUpdate = (params) => this.request("POST", "/admin/campaign/" + params.id, {
        utmsource: params.utmsource,
        type: params.type,
        urlpattern: params.urlpattern,
        // partner: params.partner.id,
        active: params.active,
        additional: params.additional,
        region: params.region,
        created: params.created
    });
    campaignDelete = (id) => this.request("DELETE", "/admin/campaign/" + id);


    actionList = (params) =>
        this.request("POST", "/admin/action/list", {
                page: params.page,
                size: params.size,
                orders: params.orders,
                login: params.loginFilter,
                type: params.typeFilter,
                utmSource: params.utmSourceFilter,
                utmCampaign: params.utmCampaignFilter,
                utmMedium: params.utmMediumFilter,
                utmContent: params.utmContentFilter,
                from: getDateFromTimeFilter(params.fromFilter),
                to: getDateToTimeFilter(params.toFilter),
                region: getRegion()
            }
        );
    actionCSV = (params) =>
        this.request("POST", "/admin/action/csv", {
                orders: params.orders,
                login: params.loginFilter,
                type: params.typeFilter,
                utmSource: params.utmSourceFilter,
                utmCampaign: params.utmCampaignFilter,
                utmMedium: params.utmMediumFilter,
                utmContent: params.utmContentFilter,
                from: getDateFromTimeFilter(params.fromFilter),
                to: getDateToTimeFilter(params.toFilter),
                region: getRegion()
            }
        );
    userpaymentList = (params) => this.request("POST", "/admin/userpayment/list", {
        page: params.page,
        size: params.size,
        orders: params.orders,
        type: params.typeFilter,
        login: params.loginFilter,
        from: getDateFromTimeFilter(params.fromFilter),
        to: getDateToTimeFilter(params.toFilter),
        region: getRegion()
    });
    userpaymentCSV = (params) => this.request("POST", "/admin/userpayment/csv", {
        orders: params.orders,
        type: params.typeFilter,
        login: params.loginFilter,
        from: getDateFromTimeFilter(params.fromFilter),
        to: getDateToTimeFilter(params.toFilter),
        region: getRegion()
    });
    betList = (params) => this.request("POST", "/admin/bet/list", {
        page: params.page,
        size: params.size,
        orders: params.orders,
        login: params.loginFilter,
        bonus: params.bonusFilter,
        from: getDateFromTimeFilter(params.fromFilter),
        to: getDateToTimeFilter(params.toFilter),
        region: getRegion()
    });
    betCSV = (params) => this.request("POST", "/admin/bet/csv", {
        orders: params.orders,
        login: params.loginFilter,
        bonus: params.bonusFilter,
        from: getDateFromTimeFilter(params.fromFilter),
        to: getDateToTimeFilter(params.toFilter),
        region: getRegion()
    });
    dynamicloadList = (params) =>
        this.request("POST", "/admin/dynamicload/list", {
            page: params.page,
            size: params.size,
            orders: params.orders,
            login: params.loginFilter,
            utmSource: params.utmSourceFilter,
            utmCampaign: params.utmCampaignFilter,
            utmMedium: params.utmMediumFilter,
            utmContent: params.utmContentFilter,
            afPrt: params.afPrtFilter,
            utmTerm: params.termFilter,
            platform: params.platformFilter,
            organic: params.organic,
            referer: params.refererFilter,
            promo: params.promoFilter,
            regDateTo: getDateToTimeFilter(params.RegDateTo),
            identDateTo: getDateToTimeFilter(params.IdentDateTo),
            ftdDateTo: getDateToTimeFilter(params.FTDDateTo),
            paymentDateTo: getDateToTimeFilter(params.PaymentDateTo),
            betsDateTo: getDateToTimeFilter(params.BetsDateTo),
            regDateFrom: getDateFromTimeFilter(params.RegDateFrom),
            identDateFrom: getDateFromTimeFilter(params.IdentDateFrom),
            ftdDateFrom: getDateFromTimeFilter(params.FTDDateFrom),
            paymentDateFrom: getDateFromTimeFilter(params.PaymentDateFrom),
            betsDateFrom: getDateFromTimeFilter(params.BetsDateFrom),
            selectedHeaders: params.selectedHeaders,
            region: getRegion()
        });
    dynamicloadCSV = (params) =>
        this.request("POST", "/admin/dynamicload/csv", {
            orders: params.orders,
            login: params.loginFilter,
            utmSource: params.utmSourceFilter,
            utmCampaign: params.utmCampaignFilter,
            utmMedium: params.utmMediumFilter,
            utmContent: params.utmContentFilter,
            afPrt: params.afPrtFilter,
            utmTerm: params.termFilter,
            platform: params.platformFilter,
            organic: params.organic,
            referer: params.refererFilter,
            promo: params.promoFilter,
            regDateTo: getDateToTimeFilter(params.RegDateTo),
            identDateTo: getDateToTimeFilter(params.IdentDateTo),
            ftdDateTo: getDateToTimeFilter(params.FTDDateTo),
            paymentDateTo: getDateToTimeFilter(params.PaymentDateTo),
            betsDateTo: getDateToTimeFilter(params.BetsDateTo),
            regDateFrom: getDateFromTimeFilter(params.RegDateFrom),
            identDateFrom: getDateFromTimeFilter(params.IdentDateFrom),
            ftdDateFrom: getDateFromTimeFilter(params.FTDDateFrom),
            paymentDateFrom: getDateFromTimeFilter(params.PaymentDateFrom),
            betsDateFrom: getDateFromTimeFilter(params.BetsDateFrom),
            selectedHeaders: params.selectedHeaders,
            region: getRegion()
        });

    postbackList = (params) => this.request("POST", "/admin/postback/list", {
        page: params.page,
        size: params.size,
        orders: params.orders,
        type: params.typeFilter,
        source: params.utmSourceFilter,
        content: params.utmContentFilter,
        campaign: params.utmCampaignFilter,
        from: getDateFromTimeFilter(params.fromFilter),
        to: getDateToTimeFilter(params.toFilter),
        region: getRegion()
    });

    postbackCSV = (params) => this.request("POST", "/admin/postback/csv", {
        orders: params.orders,
        type: params.typeFilter,
        source: params.utmSourceFilter,
        content: params.utmContentFilter,
        campaign: params.utmCampaignFilter,
        from: getDateFromTimeFilter(params.fromFilter),
        to: getDateToTimeFilter(params.toFilter),
        region: getRegion()
    });

    resendLogin = (id) => this.request("PUT", "/admin/resend/login", {
        id,
    });
    resendPayment = (id) => this.request("PUT", "/admin/resend/payment", {
        id,
    });
    resendBet = (id) => this.request("PUT", "/admin/resend/bet", {
        id,
    });

    request = (method, url, data) => {
        return axios({
            method: method,
            url: this.baseUrl + url,
            data: data,
            headers: {
                "Authorization": "Basic " + btoa(this.getAuth()),
                "Content-Type": "application/json"
            }
        })
    };

    getAuth = () => localStorage.getItem("auth");
    getActions = () => {
        const str = localStorage.getItem("actions");
        return str ? str.split(",") : []
    };
    setAuth = (auth) => localStorage.setItem("auth", auth);
    setActions = (actions) => localStorage.setItem("actions", actions);
    clearAuth = () => {
        localStorage.removeItem("auth");
        localStorage.removeItem("actions");
    };

    checkAuth = () => this.login().then((resp) => {
        if (resp.data.code === 200) {
            this.setActions(resp.data.data);
            return true
        } else {
            this.clearAuth();
            return false
        }
    }, () => {
        this.clearAuth();
        return false
    });
}

export default new Rest()