import EditModal from "../../../components/edit/EditModal";
import React from "react";
import Rest from "../../../rest/Rest";

let fields = [
    {
        key: "id",
        name: "ID",
        sortable: true,
        editable: false
    },
    {
        key: "active",
        name: "Активность",
        sortable: true,
        type: "bool",
        initValue: true
    },
    {
        key: "type",
        name: "Тип события",
        sortable: true,
        type: "enum",
        initValue: "REGISTRATION",
        enum: [
            "REGISTRATION",
            "FIRST_PAYMENT",
            "ANY_PAYMENT",
            "BASELINE_PAYMENT"
        ]
    },
    {
        key: "partner",
        name: "ID партнера",
        sortable: false,
        type: "custom",
        editable: false,
        custom: (item) => item.partner.id
    },
    {
        key: "urlpattern",
        name: "Шаблон",
        sortable: true
    },
    {
        key: "utmsource",
        name: "utm_source",
        editable: false,
        sortable: true
    },
    {
        key: "region",
        name: "Регион",
        sortable: true,
        type: "enum",
        initValue: "CUPIS",
        enum: [
            "CUPIS",
            "KZ",
            "ALL"
        ]
    },
    {
        key: "additional",
        name: "Дополнительно",
        sortable: true
    },
    {
        key: "created",
        name: "Дата создания",
        sortable: true
    }
];

export const CampaignFields = fields.concat([{
    name: "Изменение",
    sortable: false,
    type: "custom",
    custom: (item, reloadPage) => <CampaignEditModal item={item} reloadPage={reloadPage}/>
}]);

export function CampaignCreateModal(props) {
    const handleSubmit = (params) => {
        return Rest.campaignPut(params).then((resp) => {
            if (resp.data.code !== 200) {
                alert("Ошибка!!! " + resp.data.desc);
            }
            props.reloadPage();
            return resp.data
        })
    };
    const updFields = [].concat(fields).map((field) => {
        if (field.key !== "id") { //todo зачем?
            field.editable = true;
        }
        return field
    });

    return <EditModal fields={updFields} item={props.item} reloadPage={props.reloadPage} onSubmit={handleSubmit}
                      btn={props.btn}/>
}

function CampaignEditModal(props) {
    const handleSubmit = (params) => {
        return Rest.campaignUpdate(params).then((resp) => {
            if (resp.data.code !== 200) {
                alert("Ошибка!!! " + resp.data.desc);
            }
            props.reloadPage();
            return resp.data
        })
    };

    const handleDelete = (id) => {
        return Rest.campaignDelete(id).then((resp) => {
            if (resp.data.code !== 200) {
                alert("Ошибка!!! " + resp.data.desc);
            }
            props.reloadPage();
            return resp.data
        })
    };

    return <EditModal fields={fields} item={props.item} reloadPage={props.reloadPage} onSubmit={handleSubmit}
                      onDelete={handleDelete} btn={props.btn}/>
}
