import Rest from "../../rest/Rest";
import {Redirect} from 'react-router-dom'
import React from 'react';
import LoginForm from './LoginForm';
import styles from './LoginPage.module.css'

class LoginPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {redirectToReferrer: false};

        this.handleChangeRedirectToReferer = this.handleChangeRedirectToReferer.bind(this);

        Rest.checkAuth().then((auth) => {
            if (auth) this.handleChangeRedirectToReferer(true)
        })
    }

    handleChangeRedirectToReferer(redirect) {
        this.setState({redirectToReferrer: redirect});
    }

    render() {
        let {from} = this.props.location.state || {from: {pathname: "/"}};
        let {redirectToReferrer} = this.state;

        if (redirectToReferrer) return <Redirect to={from}/>;

        return (
            <div className={styles.loginPage}>
                <h2>Вход</h2>
                <LoginForm redirect={this.state.redirectToReferrer}
                           onRedirectChange={this.handleChangeRedirectToReferer}/>
            </div>
        );
    }
}

export default LoginPage