import React from 'react';
import styles from './PartnerPage.module.css'
import {LeftBar} from "../../components/bar/LeftBar";
import Rest from "../../rest/Rest";
import Table from "../../components/table/Table";
import {getNewOrders, getPageParams, safeRender, setAllUrlParams, setUrlParam} from "../../components/page/Page";
import Pagination from "../../components/pagination/Pagination";
import PageStatus from "../../components/PageStatus";
import Filter from "../../components/filter/Filter";
import Loader from 'react-loader';
import {PartnerCreateModal, PartnerFields} from "./fields/PartnerFields";

class PartnerPage extends React.Component {
    fileDownload = require('js-file-download');
    headers = PartnerFields;
    state = {modalOpened: false, data: null, status: null, loaded: true};

    componentDidMount() {
        const params = getPageParams(this.props.location.search);
        this.setState({page: params.page, size: params.size, orders: params.orders}, this.loadPage);
    }

    handleMove = (event) => {
        event.preventDefault();
        const target = event.target;
        const {page} = target.dataset;
        setUrlParam(this.props.location.search, this.props.history, "page", page);
        this.setState({page}, this.loadPage);
    };

    handleHeaderClick = (e) => {
        const {target} = e;
        const newOrders = getNewOrders(target, this.state.orders);

        setUrlParam(this.props.location.search, this.props.history, "orders", JSON.stringify(newOrders));
        this.setState({orders: newOrders}, this.loadPage);
    };

    loadPage = () => {
        this.setState({status: PageStatus.LOADING, loaded: false});
        Rest.partnerList(this.state).then((resp) => {
            if (resp.data.code === 200) {
                this.setState({
                    data: resp.data.data,
                    size: resp.data.data.size
                });
                this.setState({status: PageStatus.LOADED, loaded: true});
            } else {
                this.setState({status: PageStatus.ERROR, loaded: true});
            }
        }, (error) => {
            console.error(error);
            Rest.clearAuth();
            this.setState({status: PageStatus.ERROR, loaded: true});
        })
    };

    handleSizeChange = (size) => {
        if (size > 0) {
            const fixedSize = size > 1000 ? 1000 : size;
            this.setState({size: fixedSize})
        }
    };

    handleFilterSubmit = (e) => {
        e.preventDefault();
        this.setState({page: 0}, () => {
            setAllUrlParams(this.props.location.search, this.props.history, [
                {
                    name: "size",
                    value: this.state.size
                }
            ]);
            this.loadPage()
        })
    };

    onClickCsv = (e) => {
        let conf;
        if (this.state.data.total < 100000) {
            conf = window.confirm("Вы уверены, что хотите выгрузить " + this.state.data.total + " записей в csv файл?");
        } else {
            window.alert("Число записей для выгрузки превышает максимальное (100 000). Пожалуйста, добавьте фильтры, что бы уменьшить данные для выгрузки.");
            conf = false;
        }
        if (conf) {
            this.setState({loaded: false});
            Rest.partnerCSV(this.state).then((resp) => {
                this.setState({loaded: true});
                if (resp.status === 200) {
                    this.fileDownload(resp.data, 'partner.csv')
                } else {
                    console.error(resp);
                    alert("При скачивании csv файла произошла ошибка");
                }
            }, (error) => {
                this.setState({loaded: true});
                console.error(error);
                alert("При скачивании csv файла произошла ошибка");
            })
        }
    };

    render() {
        const csvLink = (this.state.data !== null) ?
            <button className={styles.csv_link} onClick={this.onClickCsv}>Скачать в csv</button> : null;

        const content = safeRender(this.state.status, () => {
                return (
                    <div className={styles.column}>
                        <Loader loaded={this.state.loaded}/>
                        <h1>Партнеры</h1>
                        <Filter size={this.state.size} total={this.state.data.total}
                                onSizeChange={this.handleSizeChange}
                                handleSubmit={this.handleFilterSubmit}
                                filters={[]}/>

                        <div className={styles.action_bar}>
                            {csvLink}
                            <PartnerCreateModal reloadPage={this.loadPage}
                                                btn={<div className={styles.create_btn}>Cоздать</div>}/>
                        </div>

                        <Table headers={this.headers} content={this.state.data} orders={this.state.orders}
                               onHeaderClick={this.handleHeaderClick} reloadPage={this.loadPage}/>
                        <Pagination info={this.state.data} onMove={this.handleMove}/>
                    </div>
                );
            }
        );
        return (
            <div className={styles.main}>
                <LeftBar/>
                {content}
            </div>
        );
    }
}

export default PartnerPage