import {Link} from "react-router-dom";
import React from "react";
import styles from './LeftBar.module.css'
import Rest from "../../rest/Rest";

export function LeftBar() {
    const actions = Rest.getActions() || [];

    const links = [
        {link: "partner", name: "Партнеры"},
        {link: "campaign", name: "Кампании"},
        {link: "action", name: "События"},
        {link: "postback", name: "Постбэки"},
        {link: "dynamicload", name: "Выгрузка"},
        {link: "resend", name: "Переотправка"}
    ];
    const linksEnt = links.map((e) =>
        actions.includes(e.link) ?
            <div key={e.link} className={styles.bar_item}>
                <Link to={'/' + e.link}>{e.name}</Link>
            </div> : null
    );
    return (
        <div className={styles.left_bar}>
            <div className={styles.left_bar_top}>
                <div className={styles.bar_item}>
                    <Link to={'/'}>Домой</Link>
                </div>
                {linksEnt}
            </div>

            <div className={styles.left_bar_bottom}>
                <div className={styles.bar_item}>
                    <Link to={'/logout'}>Выход</Link>
                </div>
            </div>
        </div>
    );
}
