import React from 'react';
import styles from './PostbackPage.module.css'
import {LeftBar} from "../../components/bar/LeftBar";
import Rest from "../../rest/Rest";
import Table from "../../components/table/Table";
import {
    getNewOrders,
    getPageParams,
    getUrlParam,
    safeRender,
    setAllUrlParams,
    setUrlParam, getEpochFromTimeFilter
} from "../../components/page/Page";
import PageStatus from "../../components/PageStatus";
import Pagination from "../../components/pagination/Pagination";
import Filter from "../../components/filter/Filter";
import {PostbackFields} from "./fields/PostbackFields";
import Loader from 'react-loader';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Cross from "../../components/icons/Cross";
import {nowDate, showDate} from "../../components/page/Page";
import RegionSelector from "../../components/regionSelector/RegionSelector";


class PostbackPage extends React.Component {
    fileDownload = require('js-file-download');

    headers = PostbackFields;
    filters = () => [
        {
            name: "Тип",
            input: <select name="typeFilter" value={this.state.typeFilter} onChange={this.handleInputChange}>
                <option value="">---</option>
                <option value="REGISTRATION">REGISTRATION</option>
                <option value="FIRST_PAYMENT">FIRST_PAYMENT</option>
                <option value="ANY_PAYMENT">ANY_PAYMENT</option>
                <option value="BASELINE_PAYMENT">BASELINE_PAYMENT</option>
            </select>
        },
        {
            name: "utm_source",
            input: <input type="text" name="utmSourceFilter" value={this.state.utmSourceFilter}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "utm_content",
            input: <input type="text" name="utmContentFilter" value={this.state.utmContentFilter}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "utm_campaign",
            input: <input type="text" name="utmCampaignFilter" value={this.state.utmCampaignFilter}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "дата",
            input: <div className={styles.dateRoot}>
                <DateRangePicker startDate={this.state.fromFilter || nowDate()}
                                 endDate={this.state.toFilter || nowDate()}
                                 onApply={this.handleChangeDateRange}>
                    <div className={styles.dateInput}>
                        {showDate(this.state.fromFilter)} - {showDate(this.state.toFilter)}
                    </div>
                </DateRangePicker>
                <Cross onClick={this.handleClearDateRange}/>
            </div>
        }
    ];

    state = {data: null, status: null};

    componentDidMount() {
        const params = getPageParams(this.props.location.search);
        const typeFilterParam = getUrlParam(this.props.location.search, "typeFilter") || null;
        const utmSourceFilterParam = getUrlParam(this.props.location.search, "utmSourceFilter") || null;
        const utmContentFilterParam = getUrlParam(this.props.location.search, "utmContentFilter") || null;
        const utmCampaignFilterParam = getUrlParam(this.props.location.search, "utmCampaignFilter") || null;
        const toFilterParam = getUrlParam(this.props.location.search, "toFilter") || null;
        const fromFilterParam = getUrlParam(this.props.location.search, "fromFilter") || null;

        let from = null;
        if (fromFilterParam) {
            from = new Date(0);
            from.setUTCMilliseconds(fromFilterParam)
        }
        let to = null;
        if (toFilterParam) {
            to = new Date(0);
            to.setUTCMilliseconds(toFilterParam)
        }
        this.setState({
            page: params.page, size: params.size, orders: params.orders,
            typeFilter: typeFilterParam,
            utmSourceFilter: utmSourceFilterParam,
            utmContentFilter: utmContentFilterParam,
            utmCampaignFilter: utmCampaignFilterParam,
            toFilter: to,
            fromFilter: from,
            loaded: true
        }, this.loadPage);
    }

    handleMove = (event) => {
        event.preventDefault();
        const target = event.target;
        const {page} = target.dataset;
        setUrlParam(this.props.location.search, this.props.history, "page", page);
        this.setState({page}, this.loadPage);
    };

    handleHeaderClick = (e) => {
        const {target} = e;
        const newOrders = getNewOrders(target, this.state.orders);

        setUrlParam(this.props.location.search, this.props.history, "orders", JSON.stringify(newOrders));
        this.setState({orders: newOrders}, this.loadPage);
    };

    loadPage = () => {
        this.setState({status: PageStatus.LOADING, loaded: false});
        Rest.postbackList(this.state).then((resp) => {
            if (resp.data.code === 200) {
                this.setState({
                    data: resp.data.data,
                    size: resp.data.data.size
                });
                this.setState({status: PageStatus.LOADED, loaded: true});
            } else {
                console.error(resp);
                this.setState({status: PageStatus.ERROR, loaded: true});
            }
        }, (error) => {
            console.error(error);
            Rest.clearAuth();
            this.setState({status: PageStatus.ERROR, loaded: true});
        })
    };

    handleChangeDateRange = (e, p) => {
        this.setState({
            fromFilter: p.startDate.toDate(),
            toFilter: p.endDate.toDate()
        });
    };
    handleClearDateRange = (e) => {
        this.setState({
            fromFilter: null,
            toFilter: null
        });
    };

    handleInputChange = (e) => {
        const {target} = e;
        const name = target.name;
        const value = target.value === "" ? null : target.value;
        this.setState({[name]: value})
    };


    handleSizeChange = (size) => {
        if (size > 0) {
            const fixedSize = size > 1000 ? 1000 : size;
            this.setState({size: fixedSize})
        }
    };

    handleFilterSubmit = (e) => {
        e.preventDefault();
        this.setState({page: 0}, () => {
            setAllUrlParams(this.props.location.search, this.props.history,
                [
                    {
                        name: "typeFilter",
                        value: this.state.typeFilter
                    },
                    {
                        name: "utmSourceFilter",
                        value: this.state.utmSourceFilter
                    },
                    {
                        name: "utmContentFilter",
                        value: this.state.utmContentFilter
                    },
                    {
                        name: "utmCampaignFilter",
                        value: this.state.utmCampaignFilter
                    },
                    {
                        name: "size",
                        value: this.state.size
                    },
                    {
                        name: "fromFilter",
                        value: getEpochFromTimeFilter(this.state.fromFilter)
                    },
                    {
                        name: "toFilter",
                        value: getEpochFromTimeFilter(this.state.toFilter)
                    }
                ]
            );
            this.loadPage()
        })
    };

    onClickCsv = (e) => {
        let conf;
        if (this.state.data.total < 100000) {
            conf = window.confirm("Вы уверены, что хотите выгрузить " + this.state.data.total + " записей в csv файл?");
        } else {
            window.alert("Число записей для выгрузки превышает максимальное (100 000). Пожалуйста, добавьте фильтры, что бы уменьшить данные для выгрузки.");
            conf = false;
        }
        if (conf) {
            this.setState({loaded: false});
            Rest.postbackCSV(this.state).then((resp) => {
                this.setState({loaded: true});
                if (resp.status === 200) {
                    this.fileDownload(resp.data, 'postback.csv')
                } else {
                    console.error(resp);
                    alert("При скачивании csv файла произошла ошибка");
                }
            }, (error) => {
                this.setState({loaded: true});
                console.error(error);
                alert("При скачивании csv файла произошла ошибка");
            })
        }
    };

    render() {
        const csvLink = (this.state.data !== null) ?
            <button className={styles.csv_link} onClick={this.onClickCsv}>Скачать в csv</button> : null;

        const content = safeRender(this.state.status, () => {
                return (
                    <div className={styles.column}>
                        <Loader loaded={this.state.loaded}/>
                        <h1>Постбэки</h1>
                        <RegionSelector/>
                        <Filter size={this.state.size} total={this.state.data.total}
                                onSizeChange={this.handleSizeChange}
                                handleSubmit={this.handleFilterSubmit} filters={this.filters()}/>
                        <div className={styles.action_bar}>
                            {csvLink}
                        </div>
                        <Table headers={this.headers} content={this.state.data} orders={this.state.orders}
                               onHeaderClick={this.handleHeaderClick} reloadPage={this.loadPage}/>
                        <Pagination info={this.state.data} onMove={this.handleMove}/>
                    </div>
                );
            }
        );
        return (
            <div className={styles.main}>
                <LeftBar/>
                {content}
            </div>
        );
    }
}

export default PostbackPage