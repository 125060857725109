import Rest from "../../rest/Rest";
import React from 'react';
import styles from './LoginForm.module.css'

class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {user: "", pass: "", error: ""};

        this.handleUserChange = this.handleUserChange.bind(this);
        this.handlePassChange = this.handlePassChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(event) {
        event.preventDefault();
        Rest.setAuth(this.state.user + ":" + this.state.pass);
        Rest.checkAuth().then((authenticated) => {
            if (authenticated) {
                this.props.onRedirectChange(true)
            } else {
                this.setState({error: "Логин или пароль введены неверно"})
            }
        })
    }

    handleUserChange(event) {
        this.setState({user: event.target.value})
    }

    handlePassChange(event) {
        this.setState({pass: event.target.value})
    }

    render() {
        let errIsActive = this.state.error !== "";
        return (
            <form className={styles.form} onSubmit={this.handleSubmit}>
                <div className={styles.error + (errIsActive ? "" : +" " + styles.inactive)}>{this.state.error}</div>
                <input className={styles.input} type="text" name="user" value={this.state.user}
                       onChange={this.handleUserChange}/>
                <input className={styles.input} type="password" name="pass" value={this.state.pass}
                       onChange={this.handlePassChange}/>
                <input className={styles.logbtn} type="submit" value="Вход"/>
            </form>
        );
    }
}

export default LoginForm