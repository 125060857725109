import React from 'react'
import styles from './EditModal.module.css'
import classNames from 'classnames'
import EditForm from "./EditForm";


class EditModal extends React.Component {
    state = {open: false};
    btn = this.props.btn || <img className={styles.edit_icon} alt="edit button" src='/edit.png'/>;

    handleOpenChange = (open) => {
        this.setState({open: open});
    };

    handleEditDone = () => {
        this.setState({open: false});
    };

    render() {
        return (
            <div className={styles.custom_column}>
                <Modal open={this.state.open} onOpenChange={this.handleOpenChange}
                           content={
                               <EditForm fields={this.props.fields} item={this.props.item} onEditDone={this.handleEditDone}
                                         onSubmit={this.props.onSubmit} onDelete={this.props.onDelete}/>
                           }
                           btn={this.btn}/>
            </div>
        );
    }

}
const Modal = (props) => {
    const handleShow = () => {
        props.onOpenChange(true)
    };

    const handleClose = () => {
        props.onOpenChange(false)
    };

    return (
        <div>
            <button className={styles.modal_button} onClick={handleShow}>{props.btn}</button>
            <div className={classNames(styles.modal, (props.open ? styles.open : null))}
                 onClick={handleClose}>
                <div className={styles.modal_content} onClick={(e) => {
                    e.stopPropagation()
                }}>
                    {props.content}
                </div>
            </div>
        </div>
    );
};

export default EditModal