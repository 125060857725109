import EditModal from "../../../components/edit/EditModal";
import React from "react";
import Rest from "../../../rest/Rest";


let fields = [
    {
        key: "id",
        name: "ID",
        sortable: true,
        editable: false
    },
    {
        key: "address",
        name: "Адрес",
        sortable: true
    },
    {
        key: "contactName",
        name: "Контактное имя",
        sortable: true
    },
    {
        key: "contactPhone",
        name: "Телефон",
        sortable: true
    },
    {
        key: "contectEmail",
        name: "email",
        sortable: true
    }
];

export const PartnerFields = fields.concat([{
    name: "Изменение",
    sortable: false,
    type: "custom",
    custom: (item, reloadPage) => {
        return (
            <PartnerEditModal item={item} reloadPage={reloadPage}/>
        );
    }
}]);

export function PartnerCreateModal(props) {
    const handleSubmit = (params) => {
        return Rest.partnerPut(params).then((resp) => {
            if (resp.data.code !== 200) {
                alert("Ошибка!!! " + resp.data.desc);
            }
            props.reloadPage();
            return resp.data
        })
    };

    return <EditModal fields={fields} item={props.item} reloadPage={props.reloadPage} onSubmit={handleSubmit}
                      btn={props.btn}/>
}

function PartnerEditModal(props) {
    const handleSubmit = (params) => {
        return Rest.partnerUpdate(params).then((resp) => {
            if (resp.data.code !== 200) {
                alert("Ошибка!!! " + resp.data.desc);
            }
            props.reloadPage();
            return resp.data
        })
    };

    const handleDelete = (id) => {
        return Rest.partnerDelete(id).then((resp) => {
            if (resp.data.code !== 200) {
                alert("Ошибка!!! " + resp.data.desc);
            }
            props.reloadPage();
            return resp.data
        })
    };

    return <EditModal fields={fields} item={props.item} reloadPage={props.reloadPage} onSubmit={handleSubmit}
                      onDelete={handleDelete} btn={props.btn}/>
}
