import {LeftBar} from "../../components/bar/LeftBar";
import React from "react";
import styles from './NotFound.module.css'

function PageNotFound() {
    return (
        <div className={styles.main}>
            <LeftBar/>
            <div className={styles.content}>
                <img src="/notfound.png" alt="not found"/>
                <h1>Упс... Что-то пошло не так</h1>
            </div>
        </div>
    );
}

export default PageNotFound