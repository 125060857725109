import React from "react";
import styles from "./RegionSelector.module.css"

class RegionSelector extends React.Component {
    state = {region: getRegion()};

    handleChange = (e) => {
        const value = e.target.value;
        if(setRegion(value)){
            this.setState({region: value})
        }
    };

    render() {
        return <div className={styles.root}>
            <div>Регион</div>
            <select name="region" onChange={this.handleChange}
                    value={this.state.region}>
                {
                    allowedRegions.map((e) => {
                        return <option key={e} value={e}>{e}</option>
                    })
                }
            </select>
        </div>
    }
}

const allowedRegions = ["CUPIS", "KZ", "ALL"];

export function getRegion() {
    const storedValue = localStorage.getItem("region");
    if (storedValue && !allowedRegions.includes(storedValue)) {
        localStorage.removeItem("region");
        return null;
    } else {
        return storedValue || "CUPIS"
    }
}
function setRegion(value) {
    if (value && !allowedRegions.includes(value)) {
        console.error("Неподдерживаемый регион");
        return false;
    } else {
        localStorage.setItem("region", value);
        return true;
    }
}

export default RegionSelector;