import React from 'react';
import styles from './ResendPage.module.css'
import {LeftBar} from "../../components/bar/LeftBar";
import rest from "../../rest/Rest";
import NumericInput from "react-numeric-input";

class ResendPage extends React.Component {
    state = {loginToResend: null, paymentToResend: null, betToResend: null};

    onResendLoginChanged = (id) => {
        this.setState({loginToResend: id})
    }
    onLoginApprove = () => {
        rest.resendLogin(this.state.loginToResend).then(e => {
            if (e.data.code === 200) {
                alert("Информация по логину " + this.state.loginToResend + " запрошена.")
                this.setState({loginToResend: null})
            } else {
                alert("Произошла ошибка " + e.data.code + ": " + e.data.desc)
            }
        })
    }
    onResendPaymentChanged = (id) => {
        this.setState({paymentToResend: id})
    }
    onPaymentApprove = () => {
        rest.resendPayment(this.state.paymentToResend).then(e => {
            if (e.data.code === 200) {
                alert("Информация по логину " + this.state.paymentToResend + " запрошена.")
                this.setState({paymentToResend: null})
            } else {
                alert("Произошла ошибка " + e.data.code + ": " + e.data.desc)
            }
        })
    }
    onResendBetChanged = (id) => {
        this.setState({betToResend: id})
    }
    onBetApprove = () => {
        rest.resendBet(this.state.betToResend).then(e => {
            if (e.data.code === 200) {
                alert("Информация по логину " + this.state.betToResend + " запрошена.")
                this.setState({betToResend: null})
            } else {
                alert("Произошла ошибка " + e.data.code + ": " + e.data.desc)
            }
        })
    }

    render() {
        return (
            <div className={styles.main}>
                <LeftBar/>
                <div className={styles.column}>
                    <h1>Переотправка данных</h1>
                    <h2>Введите id регистрации (login_id) для переотправки</h2>
                    <div>
                        <NumericInput min={0} step={1} value={this.state.loginToResend} onChange={this.onResendLoginChanged}/>
                        <button className={styles.create_btn} onClick={this.onLoginApprove}>Отправка</button>
                    </div>
                    <h2>Введите id платежа для переотправки</h2>
                    <div>
                        <NumericInput min={0} step={1} value={this.state.paymentToResend}
                                      onChange={this.onResendPaymentChanged}/>
                        <button className={styles.create_btn} onClick={this.onPaymentApprove}>Отправка</button>
                    </div>
                    <h2>Введите id ставки для переотправки</h2>
                    <div>
                        <NumericInput min={0} step={1} value={this.state.betToResend} onChange={this.onResendBetChanged}/>
                        <button className={styles.create_btn} onClick={this.onBetApprove}>Отправка</button>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResendPage