import React from "react";
import styles from './Table.module.css'
import Moment from 'moment'
import {getRegion} from "../regionSelector/RegionSelector";

function Table(props) {
    let i = 0;
    return (
        <table className={styles.table}>
            <thead>
            <tr>
                <th>№</th>
                {
                    props.headers.map((header) => {
                        let active = props.selectedHeaders ? props.selectedHeaders[header.key] || false : true;
                        if (active) {
                            if (header.sortable) {
                                const order = props.orders[header.key] ? props.orders[header.key] : 0;
                                const orderKeys = Object.keys(props.orders);
                                let orderNum = orderKeys.indexOf(header.key) + 1;

                                if (orderNum === 0) {
                                    orderNum = orderKeys.size + 1
                                }
                                let orderVisual;
                                if (order === 1) {
                                    orderVisual = <span className={styles.order}>{orderNum}⯅</span>
                                } else if (order === -1) {
                                    orderVisual = <span className={styles.order}>{orderNum}⯆</span>
                                }
                                return <th key={header.key} data-key={header.key} data-order={order}
                                           onClick={props.onHeaderClick}>
                                    {header.name}{orderVisual}
                                </th>
                            } else {
                                return <th key={header.name}>{header.name}</th>
                            }
                        }else return null
                    })
                }
            </tr>
            </thead>
            <tbody>
            {props.content.content.map((item) =>
                <tr key={item[Object.keys(item)[0]]}>
                    <td>{props.content.page * props.content.size + (++i)}</td>
                    {props.headers.map((header) => {

                        let active = props.selectedHeaders ? props.selectedHeaders[header.key] || false : true;
                        if (active) {
                            let {key = ""} = header;
                            let nestedProps = key.split('.');
                            var result = item;
                            nestedProps.forEach((prop) => {
                                result = result[prop];
                            });

                            let fmtResult;
                            switch (header.type) {
                                case "bool":
                                    fmtResult = <input className={styles.checkbox} type="checkbox" checked={result}/>;
                                    break;
                                case "date" :
                                    if (result) {
                                        const offset = getRegion()==="KZ" ? 6 : 3;
                                        const zone = getRegion()==="KZ" ? "ALMT" : "MSK";
                                        fmtResult = Moment(result).utcOffset(offset).format("YYYY-MM-DD HH:mm:ss") + " " + zone;
                                    } else {
                                        fmtResult = null;
                                    }
                                    break;
                                case "custom" :
                                    fmtResult = header.custom(item, props.reloadPage);
                                    break;
                                default:
                                    fmtResult = result;
                                    break;
                            }
                            return <td key={header.name}>{fmtResult}</td>
                        }else return null
                    }
                    )}
                </tr>
            )}
            </tbody>
        </table>
    );
}

export default Table