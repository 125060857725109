import React from 'react';
import './App.css';
import LoginPage from "./pages/auth/LoginPage";
import HomePage from "./pages/home/HomePage";
import Rest from "./rest/Rest";
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import PageNotFound from "./pages/status/NotFound";
import PartnerPage from "./pages/partner/PartnerPage";
import CampaignPage from "./pages/campaign/CampaignPage";
import ActionPage from "./pages/action/ActionPage";
import PostbackPage from "./pages/postback/PostbackPage";
import DynamicLoadPage from "./pages/dynamicload/DynamicLoadPage";
import ResendPage from "./pages/resend/ResendPage";


function App() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/login" component={LoginPage}/>
                <Route path="/logout" component={Logout}/>
                <PrivateRoute exact path="/" component={HomePage}/>
                <PrivateRoute path="/partner" component={PartnerPage}/>
                <PrivateRoute path="/campaign" component={CampaignPage}/>
                <PrivateRoute path="/action" component={ActionPage}/>
                <PrivateRoute path="/postback" component={PostbackPage}/>
                <PrivateRoute path="/dynamicload" component={DynamicLoadPage}/>
                <PrivateRoute path="/resend" component={ResendPage}/>
                <PrivateRoute component={PageNotFound}/>
            </Switch>
        </BrowserRouter>
    );
}

function Logout() {
    Rest.clearAuth();
    return <Redirect to={"/"}/>
}

function PrivateRoute({component: Component, ...rest}) {
    return (
        <Route
            {...rest}
            render={props => Rest.getAuth() ? (
                <Component {...props} />
            ) : (
                <Redirect
                    to={{
                        pathname: "/login",
                        state: {from: props.location}
                    }}
                />
            )
            }
        />
    );
}

export default App;
