import PageStatus from "../../components/PageStatus";
import Moment from "moment";

export let safeRender = (status, getContent) => {
    let renderContent;
    switch (status) {
        case PageStatus.LOADING: {
            //todo preloader
            break;
        }
        case PageStatus.LOADED: {
            renderContent = getContent();
            break;
        }
        case PageStatus.ERROR: {
            renderContent = "error";
            break;
        }
        default: {

            break
        }
    }

    return renderContent;
};

export function getNewOrders(target, oldOrders) {
    const oldValue = target.dataset.order || 0;

    let newValue;
    switch (oldValue) {
        case "0" : {
            newValue = 1;
            break;
        }
        case "1" : {
            newValue = -1;
            break
        }
        case "-1" : {
            newValue = 0;
            break
        }
        default: {
            newValue = oldValue;
            break
        }
    }

    const newOrders = Object.assign(oldOrders, {[target.dataset.key]: newValue});
    if (newValue === 0) {
        delete newOrders[target.dataset.key]
    }

    return newOrders
}

export function setUrlParam(search, history, name, value) {
    const searchParams = new URLSearchParams(search);
    if (value != null) {
        searchParams.set(name, value);
    }else{
        searchParams.delete(name)
    }
    history.push('?' + searchParams);
}

export function setAllUrlParams(search, history, params) {
    const searchParams = new URLSearchParams(search);
    params.forEach((param) => {
        if (param.value != null) {
            searchParams.set(param.name, param.value);
        }else{
            searchParams.delete(param.name)
        }
    });
    history.push('?' + searchParams);
}

export function getUrlParam(search, name) {
    const searchParams = new URLSearchParams(search);
    return searchParams.get(name) || '';
}

export function getPageParams(search) {
    const page = getUrlParam(search, "page") || 0;
    const size = getUrlParam(search, "size") || 20;
    let orders;
    try {
        orders = JSON.parse(getUrlParam(search, "orders")) || {};
    } catch (e) {
        orders = {}
    }

    return {
        page: page,
        size: size,
        orders: orders
    }
}

export const nowDate = () => new Date();

export const showDate = (date) => {
    if (date) {
        return Moment(date).format("YYYY.MM.DD")
    } else {
        return "----.--.--";
    }
};

export function getEpochFromTimeFilter(obj) {
    return obj ? obj.getTime() : null
}

export function getDateFromTimeFilter(obj) {
    return obj ? Moment(obj).format("YYYY.MM.DD") : null
}
export function getDateToTimeFilter(obj) {
    return obj ? Moment(new Date(obj.getTime() + 10)).format("YYYY.MM.DD") : null
}