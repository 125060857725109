import React from 'react'
import {LeftBar} from '../../components/bar/LeftBar'
import styles from './HomePage.module.css'

function HomePage() {
    return (
        <div className={styles.main}>
            <LeftBar/>
            <div>
            </div>
        </div>
    )
}

export default HomePage