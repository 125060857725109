import React from "react";
import styles from "./EditForm.module.css";

export function EditInput(props) {
    const {editable = true} = props.field;
    let fmtResult = null;

    switch (props.field.type) {
        case "bool":
            fmtResult = [
                <label key={"label"}>{props.field.name}</label>,
                <input key={"input"} disabled={!editable} type="checkbox" checked={props.result}
                       onChange={props.onFieldChanged}
                       data-field={props.field.key}/>
            ];
            break;
        case "enum" :
            fmtResult = [
                <label key={"label"}>{props.field.name}</label>,
                <select key={"input"} disabled={!editable}
                        data-field={props.field.key}
                        onChange={props.onFieldChanged}
                        value={props.result}>
                    {
                        props.field.enum.map((it) => {
                            return <option key={it} value={it}>{it}</option>
                        })
                    }
                </select>
            ];
            break;
        case "custom" :
            if (props.field.key) {
                const {result = ""} = props;
                fmtResult = [
                    <label key={"label"}>{props.field.name}</label>,
                    <input key={"input"} disabled={!editable} type="text"
                           value={props.field.custom({[props.field.key]: result})}
                           onChange={props.onFieldChanged}
                           data-field={props.field.key}/>
                ]
            } else fmtResult = null;
            break;
        default:
            fmtResult = [
                <label key={"label"}>{props.field.name}</label>,
                <input key={"input"} disabled={!editable} type="text" value={props.result}
                       onChange={props.onFieldChanged}
                       data-field={props.field.key}/>
            ];
            break;
    }
    return fmtResult
}


class EditForm extends React.Component {
    handleUpdate = (e) => {
        const {target} = e;
        const {field} = target.dataset;
        const {type = ""} = this.props.fields.find((el) => el.key === field);

        let value;
        switch (type) {
            case "bool":
                value = target.checked;
                break;
            default:
                value = target.value;
                break;
        }

        this.setState({[field]: value})
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.onSubmit(this.state).then((data) => {
            this.props.onEditDone();
        })
    };

    handleDelete = (e) => {
        e.preventDefault();
        if (window.confirm("Это действие повлечет за собой удаление!!!")) {
            this.handleDeleteConfirm()
        }
    };

    handleDeleteConfirm = () => {
        this.props.onDelete(this.state.id).then((data) => {
            this.props.onEditDone();
        })
    };

    state = {};

    componentDidMount() {
        this.props.fields.forEach((field) => {
            const {item = ""} = this.props;
            const value = item[field.key];
            const updValue = value === undefined ? (field.initValue) : value;
            this.setState({[field.key]: updValue})
        })
    }

    render() {
        const deleteButton = this.props.onDelete ?
            <button className={styles.delete_btn} onClick={this.handleDelete}>Удалить</button> : null;
        return (
            <form className={styles.edit_form} onSubmit={this.handleSubmit}>
                {
                    this.props.fields.map((field) => {
                        return <EditInput key={field.key} field={field} result={this.state[field.key]}
                                          onFieldChanged={this.handleUpdate}/>
                    })
                }

                <div className={styles.edit_form_buttons}>
                    <input className={styles.save_btn} type={"submit"} value={"Сохранить"} onClick={this.handleSubmit}/>
                    {deleteButton}
                </div>
            </form>

        );
    }
}

export default EditForm