import React from 'react';
import styles from './Pagination.module.css';
import classNames from 'classnames'

function Pagination(props) {
    let info = props.info;
    let lastPageIsFull = (info.total % info.size) === 0;
    let totalPages = Math.floor(info.total / info.size) + (lastPageIsFull ? -1 : 0);

    let hasNext = true;//info.page < totalPages;
    let hasNext10 = true;//(info.page + 10) < totalPages;
    let hasPrev = info.page > 0;
    let hasPrev10 = info.page > 10;

    return (
        <div className={styles.root}>
            <div className={styles.button} onClick={props.onMove} data-page={0}>1</div>
            <div className={classNames(styles.button, styles.inactive)}>...</div>
            <div className={classNames(styles.button, hasPrev10 ? null : styles.disable) }
                 onClick={props.onMove} data-page={info.page - 10}>&laquo;</div>
            <div className={classNames(styles.button, hasPrev ? null : styles.inactive)}
                 onClick={props.onMove} data-page={info.page - 1}>&lt;</div>

            <div className={styles.button}>{info.page + 1}</div>

            <div className={classNames(styles.button, hasNext ? null : styles.inactive)}
                 onClick={props.onMove} data-page={info.page + 1}>&gt;</div>
            <div className={classNames(styles.button, hasNext10 ? null : styles.disable)}
                 onClick={props.onMove} data-page={info.page + 10}>&raquo;</div>
            <div className={classNames(styles.button, styles.inactive)}>...</div>
            <div className={styles.button} onClick={props.onMove} data-page={totalPages}>{totalPages + 1}</div>
        </div>
    );
}

export default Pagination