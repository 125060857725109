
export let PostbackFields = [
    {
        key: "id",
        name: "ID",
        sortable: true
    },
    {
        key: "action.id",
        name: "ID события",
        sortable: true
    },
    {
        key: "action.login",
        name: "логин",
        sortable: true
    },
    {
        key: "campaign.type",
        name: "Тип события",
        sortable: true,
        type: "enum",
        initValue: "REGISTRATION",
        enum: [
            "REGISTRATION",
            "FIRST_PAYMENT",
            "ANY_PAYMENT",
            "BASELINE_PAYMENT"
        ]
    },
    {
        key: "campaign.partner.id",
        name: "ID партнера",
        sortable: false
    },
    {
        key: "campaign.utmsource",
        name: "utm_source",
        sortable: true
    },
    {
        key: "action.content",
        name: "utm_content",
        sortable: true
    },
    {
        key: "action.campaign",
        name: "utm_campaign",
        sortable: true
    },
    {
        key: "date",
        name: "Дата",
        sortable: true,
        type: "date"
    }
];