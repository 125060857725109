import React from "react";
import styles from './HeaderSelector.module.css'

function HeaderSelector(props) {
    return <div className={styles.root}>{
        props.headers.map((header, index) => {
            return <div key={index} className={styles.item}>
                <label>{header.name}</label>
                <input key={header.key} data-key={header.key} data-index={index} type="checkbox" checked={props.checks[header.key] || false} onChange={props.handleOnSelect}/>
            </div>
        })
    }</div>

}

export default HeaderSelector;