import React from 'react';
import styles from './DynamicLoadPage.module.css'
import {LeftBar} from "../../components/bar/LeftBar";
import Rest from "../../rest/Rest";
import {
    getEpochFromTimeFilter,
    getNewOrders,
    getPageParams,
    getUrlParam,
    nowDate,
    safeRender,
    setAllUrlParams,
    setUrlParam,
    showDate
} from "../../components/page/Page";
import Table from "../../components/table/Table";
import Pagination from "../../components/pagination/Pagination";
import PageStatus from "../../components/PageStatus";
import Filter from "../../components/filter/Filter";
import Loader from 'react-loader';
import HeaderSelector from "../../components/table/HeaderSelector";
import Multiselect from 'react-widgets/lib/Multiselect'
import 'react-widgets/dist/css/react-widgets.css';
import RegionSelector from "../../components/regionSelector/RegionSelector";
import Cross from '../../components/icons/Cross'

import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';

class DynamicLoadPage extends React.Component {
    fileDownload = require('js-file-download');

    state = {
        data: null,
        status: null,
        selectedHeaders: {},
        platformFilter: [],
        platforms: ["DESKTOP", "MOBILE", "IOS", "ANDROID"]
    };

    headers = [
        {
            key: "loginid",
            name: "loginid",
            sortable: true
        },
        {
            key: "id",
            name: "ID",
            sortable: true
        },
        {
            key: "currency",
            name: "Валюта",
            sortable: true
        },
        {
            key: "regDate",
            name: "RegDate",
            sortable: true,
            type: "date"
        },
        {
            key: "identDate",
            name: "IdentDate",
            sortable: false,
            type: "date"
        },
        {
            key: "utmsource",
            name: "utm_source",
            sortable: true
        },
        {
            key: "campaign",
            name: "utm_campaign",
            sortable: true
        },
        {
            key: "medium",
            name: "utm_medium",
            sortable: true
        },
        {
            key: "content",
            name: "utm_content",
            sortable: true
        },
        {
            key: "term",
            name: "utm_term",
            sortable: true
        },
        {
            key: "af_prt",
            name: "af_prt",
            sortable: true
        },
        {
            key: "platform",
            name: "Platform",
            sortable: true
        },
        {
            key: "referer",
            name: "Referer",
            sortable: true
        },
        {
            key: "ftdDate",
            name: "FTDDate",
            sortable: true,
            type: "date"
        },
        {
            key: "ftd",
            name: "FTD",
            sortable: true
        },
        {
            key: "sumDep",
            name: "SumDep",
            sortable: false
        },
        {
            key: "countBets",
            name: "CountBets",
            sortable: false
        },
        {
            key: "turnover",
            name: "Turnover",
            sortable: false
        },
        {
            key: "profitbet",
            name: "Profitbet",
            sortable: false
        },
        {
            key: "grossProfit",
            name: "GrossProfit",
            sortable: false
        },
        {
            key: "promo",
            name: "promo",
            sortable: true
        }
    ];

    parseDate = (text) => {
        let date = null;
        if (text) {
            date = new Date(0);
            date.setUTCMilliseconds(text)
        }
        return date;
    };
    timerId = null;

    componentDidMount() {
        const params = getPageParams(this.props.location.search);
        const loginFilterParam = getUrlParam(this.props.location.search, "loginFilter") || null;
        const utmSourceFilterParam = getUrlParam(this.props.location.search, "utmSourceFilter") || null;
        const utmCampaignFilterParam = getUrlParam(this.props.location.search, "utmCampaignFilter") || null;
        const utmMediumFilterParam = getUrlParam(this.props.location.search, "utmMediumFilter") || null;
        const utmContentFilterParam = getUrlParam(this.props.location.search, "utmContentFilter") || null;
        const afPrtFilterParam = getUrlParam(this.props.location.search, "afPrtFilter") || null;
        const termFilterParam = getUrlParam(this.props.location.search, "termFilter") || null;
        const platformFilterParam = JSON.parse(getUrlParam(this.props.location.search, "platformFilter") || "[]") || [];
        const organicParam = getUrlParam(this.props.location.search, "organic") || null;
        const refererFilterParam = getUrlParam(this.props.location.search, "refererFilter") || null;
        const promoFilterParam = getUrlParam(this.props.location.search, "promoFilter") || null;

        const RegDateTo = this.parseDate(getUrlParam(this.props.location.search, "RegDateTo") || nowDate().valueOf() + 24 * 60 * 60 * 1000);
        const IdentDateTo = this.parseDate(getUrlParam(this.props.location.search, "IdentDateTo") || null);
        const FTDDateTo = this.parseDate(getUrlParam(this.props.location.search, "FTDDateTo") || null);
        const RegDateFrom = this.parseDate(getUrlParam(this.props.location.search, "RegDateFrom") || nowDate());
        const IdentDateFrom = this.parseDate(getUrlParam(this.props.location.search, "IdentDateFrom") || null);
        const FTDDateFrom = this.parseDate(getUrlParam(this.props.location.search, "FTDDateFrom") || null);

        let defaultHeaders = {};
        const defaultActive = ["id",
            "regDate",
            "identDate",
            "ftdDate",
            "promo"
        ];
        this.headers.forEach((header) => {
            defaultHeaders = Object.assign(defaultHeaders, {[header.key]: defaultActive.includes(header.key)});
        });

        let selectedHeaders = defaultHeaders;
        try {
            selectedHeaders = JSON.parse(getUrlParam(this.props.location.search, "selectedHeaders")) || {};
        } catch (e) {
            selectedHeaders = defaultHeaders;
        }

        this.setState({
            page: params.page,
            size: params.size,
            orders: params.orders,
            utmSourceFilter: utmSourceFilterParam,
            loginFilter: loginFilterParam,
            utmCampaignFilter: utmCampaignFilterParam,
            utmMediumFilter: utmMediumFilterParam,
            utmContentFilter: utmContentFilterParam,
            afPrtFilter: afPrtFilterParam,
            termFilter: termFilterParam,
            platformFilter: platformFilterParam,
            organic: organicParam,
            refererFilter: refererFilterParam,
            promoFilter: promoFilterParam,
            selectedHeaders: selectedHeaders,
            RegDateTo,
            IdentDateTo,
            FTDDateTo,
            RegDateFrom,
            IdentDateFrom,
            FTDDateFrom,
            loaded: true
        }, this.loadPage);
    }

    handleMove = (event) => {
        event.preventDefault();
        const target = event.target;
        const {page} = target.dataset;
        setUrlParam(this.props.location.search, this.props.history, "page", page);
        this.setState({page}, this.loadPage);
    };

    handleHeaderClick = (e) => {
        const {target} = e;
        const newOrders = getNewOrders(target, this.state.orders);

        setUrlParam(this.props.location.search, this.props.history, "orders", JSON.stringify(newOrders));
        this.setState({orders: newOrders}, this.loadPage);
    };

    loadPage() {
        this.setState({status: PageStatus.LOADING, loaded: false});
        Rest.dynamicloadList(this.state)
            .then((resp) => {
                if (resp.data.code === 200) {
                    this.setState({
                        data: resp.data.data,
                        size: resp.data.data.size
                    });
                    this.setState({status: PageStatus.LOADED, loaded: true});
                } else {
                    console.error(resp);
                    this.setState({status: PageStatus.ERROR, loaded: true});
                }
            }, (error) => {
                console.error(error);
                Rest.clearAuth();
                this.setState({status: PageStatus.ERROR, loaded: true});
            })
    };

    handleChangeDateRange = (e, p) => {
        const fldName = e.target.parentElement.dataset.name;
        const fromName = fldName + "From";
        const toName = fldName + "To";
        this.setState({
            [fromName]: p.startDate.toDate(),
            [toName]: p.endDate.toDate()
        });
    };
    handleClearDateRange = (e) => {
        const fldName = e.currentTarget.parentElement.dataset.name;
        console.log(fldName);
        const fromName = fldName + "From";
        const toName = fldName + "To";
        this.setState({
            [fromName]: null,
            [toName]: null
        });
    };


    handlePlatformChange = (e) => {
        this.setState({platformFilter: e})
    };
    handlePlatformCreate = (name) => {
        let {platforms, platformFilter} = this.state;

        this.setState({
            platformFilter: [...platformFilter, name],
            platforms: [...platforms, name]
        })
    };

    handleInputChange = (e) => {
        const {target} = e;
        const name = target.name;
        const value = target.value === "" ? null : target.value;
        this.setState({[name]: value})
    };

    handleSizeChange = (size) => {
        if (size > 0) {
            const fixedSize = size > 1000 ? 1000 : size;
            this.setState({size: fixedSize})
        }
    };

    handleFilterSubmit = (e) => {
        e.preventDefault();
        this.setState({page: 0}, () => {
            setAllUrlParams(this.props.location.search, this.props.history, [
                {
                    name: "loginFilter",
                    value: this.state.loginFilter
                }, {
                    name: "size",
                    value: this.state.size
                },
                {
                    name: "typeFilter",
                    value: this.state.typeFilter
                },
                {
                    name: "utmSourceFilter",
                    value: this.state.utmSourceFilter
                },
                {
                    name: "utmCampaignFilter",
                    value: this.state.utmCampaignFilter
                },
                {
                    name: "utmMediumFilter",
                    value: this.state.utmMediumFilter
                },
                {
                    name: "utmContentFilter",
                    value: this.state.utmContentFilter
                },
                {
                    name: "afPrtFilter",
                    value: this.state.afPrtFilter
                },
                {
                    name: "termFilter",
                    value: this.state.termFilter
                },
                {
                    name: "platformFilter",
                    value: JSON.stringify(this.state.platformFilter || [])
                },
                {
                    name: "organic",
                    value: this.state.organic
                },
                {
                    name: "refererFilter",
                    value: this.state.refererFilter
                },
                {
                    name: "promoFilter",
                    value: this.state.promoFilter
                },
                {
                    name: "RegDateTo",
                    value: getEpochFromTimeFilter(this.state.RegDateTo)
                },
                {
                    name: "IdentDateTo",
                    value: getEpochFromTimeFilter(this.state.IdentDateTo)
                },
                {
                    name: "FTDDateTo",
                    value: getEpochFromTimeFilter(this.state.FTDDateTo)
                },
                {
                    name: "RegDateFrom",
                    value: getEpochFromTimeFilter(this.state.RegDateFrom)
                },
                {
                    name: "IdentDateFrom",
                    value: getEpochFromTimeFilter(this.state.IdentDateFrom)
                },
                {
                    name: "FTDDateFrom",
                    value: getEpochFromTimeFilter(this.state.FTDDateFrom)
                }
            ]);
            this.loadPage()
        })
    };

    isReady = (e) => {
        this.setState({loaded: false});
        Rest.isReportReady(this.state)
            .then((resp) => {
                if (resp.data.code !== 404) {
                    this.fileDownload(resp.data, 'report.csv');
                    clearTimeout(this.timerId);
                    this.setState({loaded: true});
                } else {
                    console.log("report waiting...")
                }
            }, (error) => {

            })
    };

    onClickCsv = (e) => {
        let conf;
        if (this.state.data.total < 100000) {
            conf = window.confirm("Вы уверены, что хотите выгрузить " + this.state.data.total + " записей в csv файл?");
        } else {
            window.alert("Число записей для выгрузки превышает максимальное (100 000). Пожалуйста, добавьте фильтры, что бы уменьшить данные для выгрузки.");
            conf = false;
        }
        if (conf) {
            this.setState({loaded: false});
            Rest.dynamicloadCSV(this.state)
                .then((resp) => {
                    this.setState({loaded: true});
                    if (resp.status === 200) {
                        this.fileDownload(resp.data, 'report.csv')
                    } else {
                        alert("При скачивании csv файла произошла ошибка " + resp.data);
                    }
                }, (error) => {
                    this.setState({loaded: true});
                    console.error(error);
                    alert("При скачивании csv файла произошла ошибка", error);
                })
        }
    };

    filters = () => [
        {
            name: "ID",
            input: <input type="text" name="loginFilter" value={this.state.loginFilter || ""}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "utm_source",
            input: <input type="text" name="utmSourceFilter" value={this.state.utmSourceFilter || ""}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "utm_campaign",
            input: <input type="text" name="utmCampaignFilter"
                          value={this.state.utmCampaignFilter || ""}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "utm_medium",
            input: <input type="text" name="utmMediumFilter" value={this.state.utmMediumFilter || ""}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "utm_content",
            input: <input type="text" name="utmContentFilter"
                          value={this.state.utmContentFilter || ""}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "utm_term",
            input: <input type="text" name="termFilter"
                          value={this.state.termFilter || ""}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "af_prt",
            input: <input type="text" name="afPrtFilter" value={this.state.afPrtFilter || ""}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "referer",
            input: <input type="text" name="refererFilter"
                          value={this.state.refererFilter || ""}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "promo",
            input: <input type="text" name="promoFilter"
                          value={this.state.promoFilter || ""}
                          onChange={this.handleInputChange}/>
        },
        {
            name: "Platform",
            input: <div className={styles.root_multiselect}>
                <Multiselect data={this.state.platforms}
                             value={this.state.platformFilter || ""}
                             onChange={this.handlePlatformChange}
                             onCreate={this.handlePlatformCreate}
                             containerClassName={styles.multiselect}/>
            </div>

        },
        {
            name: "organic",
            input: <select name="organic" onChange={this.handleInputChange}
                           value={this.state.organic || ""}>
                <option value="">---</option>
                <option value="true">Organic</option>
                <option value="false">Non organic</option>
            </select>
        },
        {
            name: "RegDate",
            input: <div className={styles.dateRoot} data-name={"RegDate"}>
                <DateRangePicker startDate={this.state.RegDateFrom || nowDate()}
                                 endDate={this.state.RegDateTo || nowDate()}
                                 onApply={this.handleChangeDateRange}>
                    <div className={styles.dateInput}>
                        {showDate(this.state.RegDateFrom)} - {showDate(this.state.RegDateTo)}
                    </div>
                </DateRangePicker>
                <Cross onClick={this.handleClearDateRange}/>
            </div>
        },
        {
            name: "IdentDate",
            input: <div className={styles.dateRoot} data-name={"IdentDate"}>
                <DateRangePicker startDate={this.state.IdentDateFrom || nowDate()}
                                 endDate={this.state.IdentDateTo || nowDate()}
                                 onApply={this.handleChangeDateRange}>
                    <div className={styles.dateInput}>
                        {showDate(this.state.IdentDateFrom)} - {showDate(this.state.IdentDateTo)}
                    </div>
                </DateRangePicker>
                <Cross onClick={this.handleClearDateRange}/>
            </div>
        },
        {
            name: "FTDDate",
            input: <div className={styles.dateRoot} data-name={"FTDDate"}>
                <DateRangePicker startDate={this.state.FTDDateFrom || nowDate()}
                                 endDate={this.state.FTDDateTo || nowDate()}
                                 onApply={this.handleChangeDateRange}>
                    <div className={styles.dateInput}>
                        {showDate(this.state.FTDDateFrom)} - {showDate(this.state.FTDDateTo)}
                    </div>
                </DateRangePicker>
                <Cross onClick={this.handleClearDateRange}/>
            </div>
        }
    ];

    handleHeaderOnSelect = (e) => {
        let old = this.state.selectedHeaders;
        let {target} = e;
        let newValue = target.checked;
        const newOrders = Object.assign(old, {[target.dataset.key]: newValue});
        if (!newValue) {
            delete newOrders[target.dataset.key]
        }
        this.setState({selectedHeaders: newOrders});
        setUrlParam(this.props.location.search, this.props.history, "selectedHeaders", JSON.stringify(newOrders));
    };

    render() {

        const csvLink = (this.state.data !== null) ?
            <button className={styles.csv_link} onClick={this.onClickCsv}>Скачать в csv</button> : null;


        const content = safeRender(this.state.status, () => {
                return (
                    <div className={styles.column}>
                        <Loader loaded={this.state.loaded}/>
                        <h1>Выгрузка</h1>
                        <RegionSelector/>
                        <Filter size={this.state.size} total={this.state.data.total}
                                onSizeChange={this.handleSizeChange}
                                handleSubmit={this.handleFilterSubmit}
                                filters={this.filters()}/>
                        <HeaderSelector
                            headers={this.headers}
                            checks={this.state.selectedHeaders}
                            handleOnSelect={this.handleHeaderOnSelect}
                        />
                        {csvLink}
                        <Table headers={this.headers} selectedHeaders={this.state.selectedHeaders}
                               content={this.state.data} orders={this.state.orders}
                               onHeaderClick={this.handleHeaderClick}/>
                        <Pagination info={this.state.data} onMove={this.handleMove}/>
                    </div>
                );
            }
        );
        return (
            <div className={styles.main}>
                <LeftBar/>
                {content}
            </div>
        );
    }
}

export default DynamicLoadPage