import React from 'react';
import styles from './CampaignPage.module.css'
import {LeftBar} from "../../components/bar/LeftBar";
import Rest from "../../rest/Rest";
import Table from "../../components/table/Table";
import {
    getNewOrders,
    getPageParams,
    getUrlParam,
    safeRender,
    setAllUrlParams,
    setUrlParam
} from "../../components/page/Page";
import PageStatus from "../../components/PageStatus";
import Pagination from "../../components/pagination/Pagination";
import Filter from "../../components/filter/Filter";
import Loader from 'react-loader';
import {CampaignCreateModal, CampaignFields} from "./fields/CampaignFields";
import RegionSelector, {getRegion} from "../../components/regionSelector/RegionSelector";
import Moment from "moment";

class CampaignPage extends React.Component {
    fileDownload = require('js-file-download');
    headers = CampaignFields;
    filters = () => [
        {
            name: "Тип",
            input: <select name="typeFilter" value={this.state.typeFilter} onChange={this.handleInputChange}>
                <option value="">---</option>
                <option value="REGISTRATION">REGISTRATION</option>
                <option value="FIRST_PAYMENT">FIRST_PAYMENT</option>
                <option value="ANY_PAYMENT">ANY_PAYMENT</option>
                <option value="BASELINE_PAYMENT">BASELINE_PAYMENT</option>
            </select>
        },
        {
            name: "utm_source",
            input: <input type="text" name="utmSourceFilter" value={this.state.utmSourceFilter}
                          onChange={this.handleInputChange}/>
        }
    ];

    state = {data: null, status: null};

    componentDidMount() {
        const params = getPageParams(this.props.location.search);
        const typeFilterParam = getUrlParam(this.props.location.search, "typeFilter") || null;
        const utmSourceFilterParam = getUrlParam(this.props.location.search, "utmSourceFilter") || null;

        this.setState({
            page: params.page, size: params.size, orders: params.orders,
            typeFilter: typeFilterParam,
            utmSourceFilter: utmSourceFilterParam,
            loaded: true
        }, this.loadPage);
    }

    handleMove = (event) => {
        event.preventDefault();
        const target = event.target;
        const {page} = target.dataset;
        setUrlParam(this.props.location.search, this.props.history, "page", page);
        this.setState({page}, this.loadPage);
    };

    handleHeaderClick = (e) => {
        const {target} = e;
        const newOrders = getNewOrders(target, this.state.orders);

        setUrlParam(this.props.location.search, this.props.history, "orders", JSON.stringify(newOrders));
        this.setState({orders: newOrders}, this.loadPage);
    };

    loadPage = () => {
        this.setState({status: PageStatus.LOADING, loaded: false});
        Rest.campaignList(this.state).then((resp) => {
            if (resp.data.code === 200) {
                const offset = getRegion() === "KZ" ? 6 : 3;
                const zone = getRegion() === "KZ" ? "ALMT" : "MSK";
                resp.data.data.content.forEach((data) => {
                    data.created = Moment(data.created).utcOffset(offset).format("YYYY-MM-DD HH:mm:ss") + " " + zone;
                });

                this.setState({
                    data: resp.data.data,
                    size: resp.data.data.size
                });
                this.setState({status: PageStatus.LOADED, loaded: true});
            } else {
                console.error(resp);
                this.setState({status: PageStatus.ERROR, loaded: true});
            }
        }, (error) => {
            console.error(error);
            Rest.clearAuth();
            this.setState({status: PageStatus.ERROR, loaded: true});
        })
    };

    handleInputChange = (e) => {
        const {target} = e;
        const name = target.name;
        const value = target.value === "" ? null : target.value;
        this.setState({[name]: value})
    };


    handleSizeChange = (size) => {
        if (size > 0) {
            const fixedSize = size > 1000 ? 1000 : size;
            this.setState({size: fixedSize})
        }
    };

    handleFilterSubmit = (e) => {
        e.preventDefault();
        this.setState({page: 0}, () => {
            setAllUrlParams(this.props.location.search, this.props.history,
                [
                    {
                        name: "typeFilter",
                        value: this.state.typeFilter
                    },
                    {
                        name: "utmSourceFilter",
                        value: this.state.utmSourceFilter
                    },
                    {
                        name: "size",
                        value: this.state.size
                    }
                ]
            );
            this.loadPage()
        })
    };

    onClickCsv = (e) => {
        let conf;
        if (this.state.data.total < 100000) {
            conf = window.confirm("Вы уверены, что хотите выгрузить " + this.state.data.total + " записей в csv файл?");
        } else {
            window.alert("Число записей для выгрузки превышает максимальное (100 000). Пожалуйста, добавьте фильтры, что бы уменьшить данные для выгрузки.");
            conf = false;
        }
        if (conf) {
            this.setState({loaded: this.state.loaded});
            Rest.campaignCSV(this.state).then((resp) => {
                this.setState({loaded: true});
                if (resp.status === 200) {
                    this.fileDownload(resp.data, 'campaign.csv')
                } else {
                    console.error(resp);
                    alert("При скачивании csv файла произошла ошибка");
                }
            }, (error) => {
                this.setState({loaded: true});
                console.error(error);
                alert("При скачивании csv файла произошла ошибка");
            })
        }
    };

    render() {
        const csvLink = (this.state.data !== null) ?
            <button className={styles.csv_link} onClick={this.onClickCsv}>Скачать в csv</button> : null;

        const content = safeRender(this.state.status, () => {
                return (
                    <div className={styles.column}>
                        <Loader loaded={this.state.loaded}/>
                        <h1>Кампании</h1>
                        <RegionSelector/>
                        <Filter size={this.state.size} total={this.state.data.total}
                                onSizeChange={this.handleSizeChange}
                                handleSubmit={this.handleFilterSubmit} filters={this.filters()}/>
                        <div className={styles.action_bar}>
                            {csvLink}
                            <CampaignCreateModal reloadPage={this.loadPage}
                                                 btn={<div className={styles.create_btn}>Cоздать</div>}/>
                        </div>
                        <Table headers={this.headers} content={this.state.data} orders={this.state.orders}
                               onHeaderClick={this.handleHeaderClick} reloadPage={this.loadPage}/>
                        <Pagination info={this.state.data} onMove={this.handleMove}/>
                    </div>
                );
            }
        );
        return (
            <div className={styles.main}>
                <LeftBar/>
                {content}
            </div>
        );
    }
}

export default CampaignPage