import React from "react";
import styles from "./Filter.module.css"
import NumericInput from 'react-numeric-input';

function Filter(props) {
    return (
        <div className={styles.filter}>
            <h3>
                Фильтры
            </h3>
            <p className={styles.label_size}>
                На странице <NumericInput min={0} max={1000} step={1} value={props.size} onChange={props.onSizeChange} onValid={props.onSizeChange}/> записей.
            </p>
            <form onSubmit={props.handleSubmit}>
                {
                    props.filters.map((filter) => {
                        return (
                            <div className={styles.input} key={filter.name}>
                                <label>{filter.name}</label>
                                {filter.input}
                            </div>
                        );
                    })
                }

                <button className={styles.submit} type="submit">
                    <img alt="find icon" src="/find.png" onClick={props.handleSubmit}/>
                </button>
            </form>

            {/*<p className={styles.label_total}>Всего записей {props.total}</p>*/}
        </div>
    );
}

export default Filter
